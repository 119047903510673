import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { EMPTY_TEXT_EDITOR } from 'constants/courses.constants'
import { QUIZ_QUESTION_TYPES } from 'constants/quiz.constants'

import { ascendingSort } from 'utils/helpers.utils'

import SharedStore from 'shared/stores/shared.store'

import { notify } from 'libs/common/notify'

import * as api from '../api/quiz.api'

export class QuizStore extends SharedStore {
  storeName = 'QuizStore'
  childApi = api

  defaultChoice = {
    body: '',
    correct: false,
    value: 0,
    removed: false,
    quizQuestionIndex: 0,
  }
  initialData = {
    afterCompletetionTextEnabled: false,
    autoChecked: false,
    canBeRetaken: true,
    pointsToPass: 0,
    enabled: false,
    quizChoices: [this.defaultChoice],
    quizQuestions: [
      {
        form: QUIZ_QUESTION_TYPES.text,
        title: '',
        removed: false,
      },
    ],
  }

  @observable saveInProgress = false
  @observable data = this.initialData

  quizChoiceBelongsToQuestion = ({ id, index, quizQuestionId, quizQuestionIndex } = {}) =>
    quizQuestionId ? quizQuestionId === id : quizQuestionIndex === index

  @computed get totalPoints() {
    const { autoChecked, quizChoices, quizQuestions } = this.data

    // TODO deliver a better way to calculate total points
    if (autoChecked) {
      const choicesToCalculate = quizQuestions.map(({ id }, index) =>
        quizChoices.reduce((sum, { correct, quizQuestionId, quizQuestionIndex, value }) => {
          const shouldAdd =
            correct &&
            this.quizChoiceBelongsToQuestion({
              id,
              index,
              quizQuestionId,
              quizQuestionIndex,
            })

          return shouldAdd ? sum + value : sum
        }, 0)
      )
      return choicesToCalculate.reduce((a, b) => a + b, 0)
    }

    return 0
  }

  fetchQuiz = async (quizId, data = {}) => {
    if (quizId) {
      const resp = await api.fetchItem(quizId, {
        expand: toJS(this.expands),
        lessonId: this.root.lessonsStore.item.id,
        ...data,
      })

      if (resp.success) {
        this.setData(resp.data)
      }
    } else {
      this.setData(this.initialData)
    }
  }

  handleInput = (name, value) => {
    this.updateData({ [name]: value })

    if (name === 'autoChecked' && value) {
      const quizQuestions = this.data.quizQuestions.map((question) => {
        if (question.form === QUIZ_QUESTION_TYPES.text) {
          return {
            ...question,
            form: QUIZ_QUESTION_TYPES.single,
          }
        }
        return question
      })
      this.updateData({ quizQuestions })
    }
  }

  handleQuestionInput = (name, value, index) => {
    const removed = this.data.quizQuestions.filter(({ removed }) => removed)
    const quizQuestions = [...this.data.quizQuestions.filter(({ removed }) => !removed), ...removed]

    quizQuestions[index] = {
      ...quizQuestions[index],
      [name]: value,
    }

    this.updateData({ quizQuestions })

    const { id } = quizQuestions[index]

    const quizChoices = this.assignQuestionIndexToChoices(index)

    const shouldResetCorrectAnswers =
      name === 'form' &&
      value === QUIZ_QUESTION_TYPES.single &&
      quizChoices.filter(
        ({ correct, quizQuestionId, quizQuestionIndex }) =>
          correct &&
          this.quizChoiceBelongsToQuestion({
            id,
            index,
            quizQuestionId,
            quizQuestionIndex,
          })
      ).length > 1

    if (shouldResetCorrectAnswers) {
      this.resetCorrectAnswers(index)
    }
  }

  resetCorrectAnswers = (index) => {
    const { id } = this.data.quizQuestions[index]

    const quizChoices = this.assignQuestionIndexToChoices(index).map((choice) => {
      const { quizQuestionId, quizQuestionIndex, value } = choice

      const shouldSetIncorrect = this.quizChoiceBelongsToQuestion({
        id,
        index,
        quizQuestionId,
        quizQuestionIndex,
      })

      if (shouldSetIncorrect) {
        return {
          ...choice,
          correct: false,
          value: -1 * Math.abs(value),
        }
      }

      return choice
    })

    this.updateData({ quizChoices })
  }

  assignQuestionIndexToChoices = (questionIndex) =>
    this.data.quizChoices.map((item) => {
      const { quizQuestionId } = item

      const hasIndex = Object.prototype.hasOwnProperty.call(item, 'quizQuestionIndex')

      if (quizQuestionId || hasIndex) {
        return item
      }

      return {
        ...item,
        quizQuestionIndex: questionIndex,
      }
    })

  handleChoiceInput = (name, value, questionIndex, index) => {
    const { form, id } = this.data.quizQuestions.filter(({ removed }) => !removed)[questionIndex]

    const shouldResetCorrectAnswers = name === 'correct' && form === QUIZ_QUESTION_TYPES.single

    if (shouldResetCorrectAnswers) {
      this.resetCorrectAnswers(questionIndex)
    }

    const quizChoices = this.assignQuestionIndexToChoices(questionIndex)

    const choice = quizChoices.filter(
      ({ removed, quizQuestionId, quizQuestionIndex }) =>
        !removed &&
        this.quizChoiceBelongsToQuestion({
          id,
          index: questionIndex,
          quizQuestionId,
          quizQuestionIndex,
        })
    )[index]

    const choiceIndex = quizChoices.indexOf(choice)

    quizChoices[choiceIndex] = {
      ...choice,
      [name]: value,
      quizQuestionIndex: questionIndex,
    }

    this.updateData({ quizChoices })
  }

  handleDeleteChoice = (questionIndex, index) => {
    const { id } = this.data.quizQuestions[questionIndex]

    const choice = this.data.quizChoices
      .filter(({ removed }) => !removed)
      .filter(({ quizQuestionId, quizQuestionIndex }) =>
        this.quizChoiceBelongsToQuestion({
          id,
          index: questionIndex,
          quizQuestionId,
          quizQuestionIndex,
        })
      )[index]

    const choiceIndex = this.data.quizChoices.indexOf(choice)

    const saved = Object.prototype.hasOwnProperty.call(this.data.quizChoices[choiceIndex], 'quizQuestionId')

    const quizChoices = saved
      ? this.data.quizChoices.map((item, index) => {
          if (index === choiceIndex) {
            return {
              ...item,
              removed: true,
            }
          }

          return item
        })
      : this.data.quizChoices.filter((item, index) => index !== choiceIndex)

    this.updateData({ quizChoices })
  }

  handleDeleteQuestion = (index) => {
    const activeQuestions = this.data.quizQuestions.reduce((result, { removed, ...item }, index) => {
      if (removed) {
        return result
      }

      return [
        ...result,
        {
          ...item,
          index,
        },
      ]
    }, [])

    const questionToDelete = activeQuestions[index]

    const saved = Object.prototype.hasOwnProperty.call(questionToDelete, 'id')

    const quizQuestions = saved
      ? this.data.quizQuestions.map((item) => {
          if (questionToDelete.id === item.id) {
            return {
              ...item,
              removed: true,
            }
          }

          return item
        })
      : this.data.quizQuestions.filter((item, index) => index !== questionToDelete.index)

    this.updateData({ quizQuestions })
  }

  addNewQuestionChoice = (quizQuestionIndex = 0) => {
    const quizChoices = [
      ...this.data.quizChoices,
      {
        ...this.defaultChoice,
        quizQuestionIndex,
      },
    ]

    this.updateData({ quizChoices })
  }

  addNewQuestion = () => {
    const quizQuestions = [
      ...this.data.quizQuestions,
      {
        form: this.data.autoChecked ? QUIZ_QUESTION_TYPES.single : QUIZ_QUESTION_TYPES.text,
        title: '',
        prefillType: null,
        quizId: this.data.id,
      },
    ]

    this.updateData({ quizQuestions })
  }

  // Save logic TODO: Refactor the save data formation

  @action setData = (data) => {
    this.data = {
      ...data,
      quizChoices: ascendingSort(data.quizChoices, 'id'),
      quizQuestions: ascendingSort(data.quizQuestions, 'id'),
    }
  }

  @action toggleSaveProgress = () => {
    this.saveInProgress = !this.saveInProgress
  }

  updateData = (data) =>
    this.setData({
      ...this.data,
      ...data,
    })

  handleSave = async () => {
    const {
      afterCompletetionTextEnabled,
      afterword,
      autoChecked,
      canBeRetaken,
      failureAfterword,
      id,
      introduction,
      pointsToPass,
      enabled,
      requiredToGoFurther,
      successAfterword,
    } = this.data

    this.root.lessonsStore.toggleLoading()
    this.toggleSaveProgress()

    const quizData = {
      afterCompletetionTextEnabled,
      afterword,
      autoChecked,
      canBeRetaken,
      failureAfterword,
      pointsToPass,
      requiredToGoFurther,
      successAfterword,
      introduction,
      enabled,
      lessonId: this.root.lessonsStore.item.id,
      quizQuestionsAttributes: this.getQuestionsAttributes(),
      expand: toJS(this.expands),
    }

    let resp = {}

    if (id) {
      resp = await api.updateItem(id, quizData)
    } else {
      resp = await api.createItem(quizData)
    }

    if (resp.success) {
      this.setData(resp.data)

      this.root.lessonsStore.item.quizId = resp.data.id
      notify('success', I18n.t('react.cabinet.product.lesson.quizzes.notify.updated'))

      this.root.lessonsStore.fetchLessons({ productId: this.root.productsStore.item.id })
    }

    this.root.lessonsStore.toggleLoading()
    this.toggleSaveProgress()
  }

  getQuestionsAttributes = () => {
    const questionsToSave = this.data.quizQuestions.filter(
      ({ id, removed }) => !!id || (removed && !!id) || (!id && !removed)
    )
    return questionsToSave.map((question, index) => {
      const { form, id, removed, title: qtitle, prefillType } = question
      const title = qtitle || ''
      const trimmedTitle = title.replace(/&nbsp;/g, '').trim()
      const formattedTitle = trimmedTitle === EMPTY_TEXT_EDITOR ? '' : title

      return {
        id,
        form,
        title: formattedTitle,
        prefillType,
        _destroy: removed ? '1' : null,
        quizChoicesAttributes: this.getChoicesAttributes(question, index),
      }
    })
  }

  getChoicesAttributes = (question, index) => {
    const choicesToSave = this.data.quizChoices.filter((item) => {
      const { id, removed, quizQuestionId, quizQuestionIndex } = item

      const hasIndex = Object.prototype.hasOwnProperty.call(item, 'quizQuestionIndex')

      const belongsToQuestion =
        (hasIndex && quizQuestionIndex === index) || (quizQuestionId && quizQuestionId === question.id)

      return belongsToQuestion && (!!id || (removed && !!id) || (!id && !removed))
    })

    return choicesToSave.map(({ body, correct, id, removed, value }) => ({
      id,
      body,
      correct,
      value,
      _destroy: removed ? '1' : null,
    }))
  }
  // End save logic

  choicesValid = ({ id }, index) =>
    this.data.quizChoices
      .filter(({ quizQuestionId, quizQuestionIndex }) =>
        this.quizChoiceBelongsToQuestion({
          id,
          index,
          quizQuestionId,
          quizQuestionIndex,
        })
      )
      .every(({ body, removed }) => !!removed || !!body)

  constructor(rootStore) {
    super()

    this.root = rootStore
    makeObservable(this)
  }
}

export default new QuizStore()
