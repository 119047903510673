import { makeAutoObservable } from 'mobx'

import { AutomationProps, createDunningAutomationApi, DunningAutomationApi } from '../api/dunningAutomation.api'

export class DunningAutomationV2Store {
  storeName = 'DunningAutomationStore'
  declare childApi: DunningAutomationApi
  isLoading = false
  automationConfig = null

  constructor(root) {
    this.childApi = createDunningAutomationApi(root.apiClient)
    makeAutoObservable(this)
  }

  updateAutomation = async (data: AutomationProps) => {
    this.isLoading = true
    const resp = await this.childApi.updateAutomation(data)
    this.isLoading = false
    return resp
  }

  createAutomation = async (data: AutomationProps) => {
    this.isLoading = true
    const resp = await this.childApi.createAutomation(data)
    this.isLoading = false
    return resp
  }

  loadAutomationConfig = async () => {
    this.isLoading = true
    const resp = await this.childApi.getAutomation()
    if (resp.responseType === 'success' && resp.data?.id) {
      this.automationConfig = resp.data
    }
    this.isLoading = false
    return resp
  }
}
