import { action, makeObservable, observable } from 'mobx'
import { TABS_KEYS } from '../constants/sidePanel.constants'
import { SCREENS } from '../constants/pageBuilder.constants'

export class PageBuilderStore {
  @observable viewMode = SCREENS.all
  @observable previewMode = false
  @observable sidePanelActiveTab = TABS_KEYS.custom
  @observable isTextModalOpen = false
  @observable text = ''
  @observable textKey = ''
  @observable textFieldType = 'textarea'
  @observable modalMessage = ''

  @action setViewMode = (value: SCREENS) => (this.viewMode = value)

  @action setPreviewMode = (value: boolean) => (this.previewMode = value)

  @action setSidePanelActiveTab = (tabKey: TABS_KEYS) => (this.sidePanelActiveTab = tabKey)

  @action toggleIsTextModalOpen = (value?: boolean) => (this.isTextModalOpen = value || !this.isTextModalOpen)

  @action setText = (value: string) => (this.text = value)

  @action setTextKey = (value: string) => (this.textKey = value)

  @action setTextFieldType = (value: string) => (this.textFieldType = value)

  @action setModalMessage = (value: string) => (this.modalMessage = value)

  @action resetTextModalData = () => {
    this.text = ''
    this.modalMessage = ''
    this.textFieldType = ''
  }

  constructor() {
    makeObservable(this)
  }
}

export default new PageBuilderStore()
