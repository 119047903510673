import SharedStore from 'shared/stores/shared.store'
import { makeObservable } from 'mobx'
import * as api from '../api/dunning.api'

export class DunningStore extends SharedStore<api.Claim> {
  childApi = api

  constructor(name: string, scope: { key: string; value: any }) {
    super()
    this.storeName = name
    this.handleScopeChange(scope.key, scope.value)
    makeObservable(this)
  }

  bulkClaim = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.createBulk(data)
    this.toggleLoading(false)
    return resp
  }

  singleClaim = async (data) => {
    this.toggleLoading(true)
    const resp = await this.childApi.singleClaim(data)
    this.toggleLoading(false)
    return resp
  }

  stopResolveClaim = async (id: number, data: { notes: string }) => {
    this.toggleLoading(true)
    const resp = await this.childApi.stopResolveClaim(id, data)
    this.toggleLoading(false)
    return resp
  }

  stopResolveBulkClaim = async (data: { ids: number[]; notes: string }) => {
    this.toggleLoading(true)
    const resp = await this.childApi.stopResolveBulkClaim(data)
    this.toggleLoading(false)
    return resp
  }
}
