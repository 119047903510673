import { makeObservable, override } from 'mobx'

import SharedStore from 'shared/stores/shared.store'

import { UpgradePlansApi, createUpgradePlansApi, UpgradePlan } from '../api/upgradePlans.api'

export class UpgradePlansStore extends SharedStore<UpgradePlan> {
  storeName = 'UpgradePlansStore'
  declare childApi: UpgradePlansApi

  constructor(root) {
    super()
    this.childApi = createUpgradePlansApi(root.apiClient)
    makeObservable(this)
  }

  @override
  async fetchList(): Promise<void> {
    if (this.list?.length) {
      return
    }
    // @ts-ignore
    const { data } = await this.childApi.fetchList()
    this.list = data
    this.loading = false
  }
}
