import { AUTO_STOP, DEBT_CONDITIONS, DUNNING_STRATEGIES } from 'constants/dunning.constants'
import type { ApiClientV2Default } from 'utils/api-client/api-client.utils'

const BASE_URL = '/cabinet/dunning_automations'

type AutoStopType = keyof typeof AUTO_STOP
type DunningStrategiesType = keyof typeof DUNNING_STRATEGIES
type DebtConditions = keyof typeof DEBT_CONDITIONS

const TYPE = 'dunning_automations'

export interface AutomationPropsPayload {
  autoStart: number
  debtValue: number | ''
  strategy: (typeof DUNNING_STRATEGIES)[DunningStrategiesType]
  autoStop: (typeof AUTO_STOP)[AutoStopType]
  debtCondition: (typeof DEBT_CONDITIONS)[DebtConditions]
}

export interface AutomationProps extends AutomationPropsPayload {
  id: number
}

export const createDunningAutomationApi = ({ PUT, GET, POST }: ApiClientV2Default) => ({
  updateAutomation: (data: AutomationProps) =>
    PUT<AutomationProps>(`${BASE_URL}`, {
      data: {
        id: data.id,
        type: TYPE,
        attributes: {
          ...data,
        },
      },
    }),
  getAutomation: () => GET<AutomationProps>(`${BASE_URL}`),
  createAutomation: (data: AutomationPropsPayload) =>
    POST<AutomationProps>(`${BASE_URL}`, {
      data: {
        type: TYPE,
        attributes: {
          ...data,
        },
      },
    }),
})

export type DunningAutomationApi = ReturnType<typeof createDunningAutomationApi>
