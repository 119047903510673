import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { observable, action, runInAction, makeObservable } from 'mobx'
import { getCookies } from 'libs/common/cookies'
import { notify } from 'libs/common/notify'
import classNames from 'classnames'

import { RadioField } from '@elo-kit/components/form/radio-field/RadioField'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { TextField } from '@elo-kit/components/elo-ui/text-field/TextField'
import { ConfirmationModal } from '@elo-kit/components/elo-ui/confirmation-modal/ConfirmationModal'
import { EloButton } from '@elo-kit/components/elo-button/EloButton'
import { SelectField } from '@elo-kit/components/form/select-field/SelectField'
import { I18nContext } from '@elo-kit/components/i18n/i18n'
import EloModal from 'ui/EloModal'
import CheckboxList from 'ui/checkbox-list/CheckboxList'
import CsvLogPreview from 'ui/elo-table/CsvLogPreview'
import SectionTabs from 'ui/SectionTabs'
import CsvScheduledReports from 'ui/elo-table/CsvScheduledReports'

import { SIZES } from '@elo-kit/constants/general.constants'
import { MAX_CSV_COUNT, MAX_CABINET_CSV_COUNT } from 'constants/csv.constants'
import { ACTIVE_PROFILES } from 'constants/profile.constants'
import {
  CSV_DOWNLOAD_TYPE,
  SEPARATOR_OPTIONS,
  CSV_REPORT_MODE_KEYS,
  getCsvReportModes,
  CSV_MODAL_TABS_KEYS,
  FREQUENCY_OPTIONS,
  DAY_MONTHLY_OPTIONS,
  TAB_TITLES,
  DAY_WEEKLY_OPTIONS,
  CSV_LOG_STATUSES,
} from 'constants/csvLogs.constants'
import { ELOPAGE_PROFILE_CABINETS, ROOT_URL } from 'constants/general.constants'

import { deepObjectPropNamesToSnakeCase } from 'utils/nameStyle.utils'
import { formatDateTime } from '@elo-kit/utils/dateTime.utils'
import { profile } from 'utils/profileHelper.utils'

import SeparatorOptionLabel from './../SeparatorOptionLabel'

import './csv-download-modal.scss'

const stores = [
  'csvLogsStore',
  'publisherStore',
  'eloPublisherStore',
  'teamMemberStore',
  'payerStore',
  'sellerStore',
  'managerStore',
]

const options = SEPARATOR_OPTIONS.map(({ value: value, label: label }) => ({
  value: value,
  label: (
    <SeparatorOptionLabel
      {...{
        value,
        label,
      }}
    />
  ),
}))

@inject(...stores)
@observer
class CsvDownloadModal extends Component {
  static contextType = I18nContext

  @observable openCsvDownloadModal = false
  @observable openCsvProcessingModal = false
  @observable csvModalData = {
    openCsvProcessingModal: false,
    openCsvSuccessModal: false,
    openCsvFinishModal: false,
  }
  @observable separatorType = SEPARATOR_OPTIONS[0].value
  @observable selectedCsvLog = {}
  @observable formData = {
    includeCustomFields: false,
    checkedOptions: {},
    csvType: CSV_DOWNLOAD_TYPE.list,
    customName: '',
  }
  @observable savedColumnsList = []
  @observable activeTab = CSV_MODAL_TABS_KEYS.download
  @observable csvReportCurrentMode = CSV_REPORT_MODE_KEYS.downloadReport
  @observable selectedFrequency = FREQUENCY_OPTIONS[0].value
  @observable selectedFrequencyDay = DAY_MONTHLY_OPTIONS[0].value
  @observable scheduledReportId = null
  @observable itemToDelete = null

  @action toggleCsvDownloadModal = () => {
    if (!this.openCsvDownloadModal) {
      this.fetchCsvList()
      this.resetSavedColumnList()
    } else {
      this.setCsvReportMode(CSV_REPORT_MODE_KEYS.downloadReport)
      this.setScheduledReportId(null)
      this.setFormDataDefault()
      this.toggleActiveTab(CSV_MODAL_TABS_KEYS.download)
    }
    this.openCsvDownloadModal = !this.openCsvDownloadModal
  }

  @action toggleCsvModal = (name, value) => {
    this.csvModalData = {
      ...this.csvModalData,
      [name]: value,
    }
  }

  @action setSavedColumnsList = (csvList) => (this.savedColumnsList = [...this.savedColumnsList, ...csvList])

  @action resetSavedColumnList = () => (this.savedColumnsList = [])

  constructor(props) {
    super(props)
    makeObservable(this)
  }

  componentDidMount() {
    const { tableHeaders, params } = this.props

    const checkedOptions = (params || tableHeaders || []).map(({ checked, ...item }) => ({
      ...item,
      checked: true,
    }))
    this.setCheckedOptions(checkedOptions)
  }

  componentDidUpdate(prevProps) {
    const { tableHeaders, params } = this.props
    if (
      JSON.stringify(prevProps.tableHeaders) !== JSON.stringify(tableHeaders) ||
      JSON.stringify(params) !== JSON.stringify(params)
    ) {
      const checkedOptions = (params || tableHeaders || []).map(({ checked, ...item }) => ({
        ...item,
        checked: true,
      }))
      this.setCheckedOptions(checkedOptions)
    }
  }

  componentWillUnmount() {
    const { csvLogsStore } = this.props
    csvLogsStore.resetData()
  }

  @action updateSelectedCsvLog = (item) => (this.selectedCsvLog = item)

  @action setSeparatorType = (value) => (this.separatorType = value)

  @action setSelectedFrequency = (value) => {
    this.selectedFrequency = value
    this.setSelectedFrequencyDay(1)
  }

  @action setSelectedFrequencyDay = (value) => (this.selectedFrequencyDay = value)

  @action setFormData = (formData) => (this.formData = formData)

  @action setFormDataDefault = () => {
    this.setFormData({
      includeCustomFields: false,
      checkedOptions: this.formData.checkedOptions.map((item) => ({ ...item, checked: true })),
      csvType: CSV_DOWNLOAD_TYPE.list,
      customName: '',
    })
    this.setSelectedFrequency(FREQUENCY_OPTIONS[0].value)
  }

  @action setSelectedSavedColumn = (selectedColumnId) => {
    const { csvLogsStore } = this.props
    const { checkedOptions } = this.formData

    const csvItem = csvLogsStore.list.find((item) => item.id === selectedColumnId) || {}
    const { separatorType, csvParams, customName, listType, createdAt, includeCustomFields, saveColumns } = csvItem

    const csvParamsValues = Object.values(csvParams)
    const updatedCheckedOptions = checkedOptions.map((item) => ({
      ...item,
      checked: csvParamsValues.includes(item.csvKey),
    }))
    const csvName = customName || `${listType.toLowerCase()}-csv-${formatDateTime(createdAt)}`

    this.setSeparatorType(separatorType)
    this.setCheckedOptions(updatedCheckedOptions)
    this.updateFormData({
      selectedSavedColumn: selectedColumnId,
      customName: csvName,
      includeCustomFields,
      saveColumns,
    })
  }

  @action toggleActiveTab = (name) => {
    this.activeTab = name

    if (name === CSV_MODAL_TABS_KEYS.scheduled) {
      this.setCsvReportMode(CSV_REPORT_MODE_KEYS.scheduledReports)
    } else {
      this.setCsvReportMode(CSV_REPORT_MODE_KEYS.downloadReport)
    }

    this.setSavedCsvColumns()
  }

  @action setCsvReportMode = (mode) => (this.csvReportCurrentMode = mode)

  @action setScheduledReportId = (id) => (this.scheduledReportId = id)

  @action setItemToDelete = (id) => (this.itemToDelete = id)

  editScheduledReport = (id) => {
    const {
      csvLogsStore: { list },
    } = this.props
    this.setScheduledReportId(id)
    this.setSelectedSavedColumn(id)
    const { schedule } = list.find((item) => item.id === id) || {}
    this.setSelectedFrequency(schedule?.dayType)
    this.setSelectedFrequencyDay(schedule?.dayValue)
    this.setCsvReportMode(CSV_REPORT_MODE_KEYS.createReport)
    this.setSavedCsvColumns()
  }

  updateFormData = (formData) => {
    this.setFormData({
      ...this.formData,
      ...formData,
    })
  }

  fetchCsvList = (payload) => {
    const {
      csvLogsStore: { fetchList },
      newCsvLayout,
    } = this.props

    fetchList(payload).then(({ data }) => {
      const { list = [] } = data || {}

      if (list.length > 0) {
        this.updateSelectedCsvLog(list[0])
        runInAction(() => (this.openCsvDownloadModal = true))

        if (newCsvLayout) {
          this.setSavedCsvColumns()
        }
      } else {
        this.setCsvType(CSV_DOWNLOAD_TYPE.custom)
      }
    })
  }

  setSavedCsvColumns = () => {
    const { csvLogsStore } = this.props

    csvLogsStore
      .fetchFullList({
        isAnExample: [CSV_REPORT_MODE_KEYS.createReport, CSV_REPORT_MODE_KEYS.scheduledReports].includes(
          this.csvReportCurrentMode
        ),
      })
      .then((resp) => {
        if (resp?.success) {
          const { list } = resp.data || {}

          const savedColumnsCsvList = list.reduce((prevItem, currentItem) => {
            const { id, customName, listType, createdAt, saveColumns } = currentItem || {}
            const label = customName || `${listType.toLowerCase()}-csv-${formatDateTime(createdAt)}`

            if (saveColumns) {
              return [
                ...prevItem,
                {
                  label,
                  value: id,
                },
              ]
            }

            return prevItem
          }, [])
          this.resetSavedColumnList()
          this.setSavedColumnsList(savedColumnsCsvList)

          if (!list.length && [CSV_REPORT_MODE_KEYS.scheduledReports].includes(this.csvReportCurrentMode)) {
            this.setFormDataDefault()
            this.setCsvReportMode(CSV_REPORT_MODE_KEYS.createReport)
          }
        }
      })
  }

  setCheckedOptions = (checkedOptions) => this.updateFormData({ checkedOptions })
  setCsvType = (csvType) => this.updateFormData({ csvType })

  csvConfiguredParams = () => {
    const data = this.formData.checkedOptions
    const obj = {}
    const headerKeys = []

    data
      .filter(({ checked }) => checked)
      .forEach((item) => {
        const { label, csvKey, sortingKey } = item
        const key = csvKey || sortingKey

        if (key) {
          headerKeys.push(label)
          obj[`csv_params[${label}]`] = key
        }
      })
    return {
      ...obj,
      header_keys: headerKeys,
    }
  }

  createCsvLog = (schedulingReport) => {
    const {
      from,
      customMethod,
      csvLogsStore: { createItem, fetchList, updateItem },
      initStore: { csvQueryParams: queryParams, storeKey, sortKey, getFormattedRelativeData = () => {} },
      newCsvLayout,
    } = this.props
    const { includeCustomFields, customName, saveColumns } = this.formData
    const I18n = this.context

    const relativeFilterDate = getFormattedRelativeData()
    const data = {
      ...this.csvConfiguredParams(),
      ...deepObjectPropNamesToSnakeCase({
        customMethod,
        listType: storeKey || from,
        separatorType: this.separatorType,
        includeCustomFields,
        customName,
        saveColumns,
        queryParams: deepObjectPropNamesToSnakeCase({
          ...(queryParams || this.props.csvQueryParams),
          ...relativeFilterDate,
          sortKey: getCookies(`${sortKey || storeKey}_sorting_key`) || 'id',
          sortDir: getCookies(`${sortKey || storeKey}_sorting_direction`) || 'desc',
        }),
      }),
      __unsafeOptions: {
        disableSnakeCaseConverting: true,
      },
    }

    if (schedulingReport) {
      data.schedule = { day_type: this.selectedFrequency, day_value: this.selectedFrequencyDay }
    }

    if (this.scheduledReportId) {
      updateItem(this.scheduledReportId, data).then((resp) => {
        if (resp.success) {
          notify('success', I18n.t('react.shared.csv_modal.schedule_edited_successfully'))
          this.setFormDataDefault()
          fetchList({ isAnExample: newCsvLayout })
          this.setCsvReportMode(CSV_REPORT_MODE_KEYS.scheduledReports)
          this.setScheduledReportId(null)
        }
      })
    } else {
      createItem(data).then((resp) => {
        if (resp.success) {
          this.setCsvType(CSV_DOWNLOAD_TYPE.list)
          this.setFormDataDefault()

          if (newCsvLayout && !schedulingReport) {
            this.toggleCsvDownloadModal()
            this.toggleCsvModal('openCsvProcessingModal', true)
          }

          fetchList({ isAnExample: newCsvLayout }).then(({ data }) => {
            this.updateSelectedCsvLog(data.list[0])
          })

          if (schedulingReport) {
            notify('success', I18n.t('react.shared.csv_modal.schedule_created_successfully'))
            this.setCsvReportMode(CSV_REPORT_MODE_KEYS.scheduledReports)
          } else {
            notify('success', I18n.t('react.shared.notific.csv_generation'))
            const csvFetching = setInterval(() => {
              fetchList().then((resp) => {
                const { data } = resp || {}
                const { state } = data?.list[0] || {}

                const csvProcessed = state === CSV_LOG_STATUSES.processed

                this.updateSelectedCsvLog(data.list[0])

                if (csvProcessed) {
                  if (newCsvLayout) {
                    this.toggleCsvModal('openCsvProcessingModal', false)
                    this.toggleCsvModal('openCsvSuccessModal', true)
                  }

                  clearInterval(csvFetching)
                }
              })
            }, 30000)
          }
        }
      })
    }
  }

  downloadCsv = () => {
    const { csvLogsStore, newCsvLayout } = this.props

    const selectedId = (this.selectedCsvLog || {}).id
    csvLogsStore.downloadCsv(selectedId)

    if (newCsvLayout) {
      this.toggleCsvModal('openCsvFinishModal', true)
    }
  }

  csvComponent = (label) => {
    const {
      render,
      csvLogsStore: { handleScopeChange },
      initStore: { storeKey },
    } = this.props
    const I18n = this.context

    return render ? (
      render({ onClick: this.toggleCsvDownloadModal })
    ) : (
      <EloButton
        id='buttonEnableCsvModal'
        download
        onClick={() => {
          handleScopeChange('listType', storeKey)
          this.toggleCsvDownloadModal()
        }}
      >
        {label || I18n.t('react.common.csv')}
      </EloButton>
    )
  }

  deleteScheduledReport = () => {
    const {
      csvLogsStore: { deleteItem },
    } = this.props
    const I18n = this.context

    deleteItem(this.itemToDelete).then((resp) => {
      if (resp.success) {
        notify('success', I18n.t('react.shared.csv_modal.schedule_deleted_successfully'))
        this.fetchCsvList({ isAnExample: true })
      }
    })
  }

  duplicateScheduledReport = (id) => {
    const {
      csvLogsStore: { duplicateItem },
    } = this.props
    const I18n = this.context

    duplicateItem(id).then((resp) => {
      if (resp.success) {
        notify('success', I18n.t('react.shared.csv_modal.schedule_copied_successfully'))
        this.fetchCsvList({ isAnExample: true })
      }
    })
  }

  render() {
    const {
      params,
      taxCsvLabel,
      tableHeaders,
      withCustomFields,
      storePagination = {},
      newCsvLayout,
      locationHash,
    } = this.props
    const { csvType, includeCustomFields, saveColumns, customName, selectedSavedColumn, checkedOptions } = this.formData
    const { openCsvProcessingModal, openCsvSuccessModal, openCsvFinishModal } = this.csvModalData
    const I18n = this.context
    const isTypeList = newCsvLayout ? false : csvType === CSV_DOWNLOAD_TYPE.list
    const processed = (this.selectedCsvLog || {}).state === CSV_LOG_STATUSES.processed
    const profileType = getCookies('cabinet_profile_type')
    const isTeamMember = profileType === ELOPAGE_PROFILE_CABINETS.team_member
    const logsCsvLink = `${ROOT_URL}/${
      isTeamMember ? ELOPAGE_PROFILE_CABINETS.seller : ELOPAGE_PROFILE_CABINETS[profileType]
    }/logs/csv`
    const {
      csvReportTitle,
      csvReportSubTitle,
      csvReportButtonCentered,
      csvReportButtonSubmitIcon,
      csvReportButtonSubmitText,
      csvReportTitleSecond,
      csvReportButtonSubmitTextSecond,
    } = getCsvReportModes(logsCsvLink)[this.csvReportCurrentMode]
    const dayOptions = this.selectedFrequency === FREQUENCY_OPTIONS[0].value ? DAY_MONTHLY_OPTIONS : DAY_WEEKLY_OPTIONS
    const buttonsClasses = classNames('elo-modal__bottom-buttons', {
      'elo-modal__bottom-buttons--centered': csvReportButtonCentered,
    })
    const eloModalClasses = classNames({
      'elo-modal--body-p-t-10': newCsvLayout,
    })
    const csvReportHandler = {
      downloadReport: {
        buttonSubmit: () => this.createCsvLog(),
      },
      scheduledReports: {
        buttonSubmit: () => {
          this.setFormDataDefault()
          this.setCsvReportMode(CSV_REPORT_MODE_KEYS.createReport)
          this.setSavedCsvColumns()
        },
      },
      createReport: {
        buttonSubmit: () => this.setCsvReportMode(CSV_REPORT_MODE_KEYS.schedulingReport),
        prevStep: () => {
          const {
            csvLogsStore: { list },
          } = this.props

          this.setFormDataDefault()
          this.setScheduledReportId(null)
          this.setSavedCsvColumns()
          if (list.length) {
            this.setCsvReportMode(CSV_REPORT_MODE_KEYS.scheduledReports)
          } else {
            this.toggleActiveTab(CSV_MODAL_TABS_KEYS.download)
            this.setCsvReportMode(CSV_REPORT_MODE_KEYS.downloadReport)
          }
        },
      },
      schedulingReport: {
        buttonSubmit: () => this.createCsvLog(true),
        prevStep: () => this.setCsvReportMode(CSV_REPORT_MODE_KEYS.createReport),
      },
    }
    const { buttonSubmit, prevStep } = csvReportHandler[this.csvReportCurrentMode]
    const getCsvReportTitle =
      [CSV_REPORT_MODE_KEYS.createReport, CSV_REPORT_MODE_KEYS.schedulingReport].includes(this.csvReportCurrentMode) &&
      this.scheduledReportId
        ? csvReportTitleSecond
        : csvReportTitle
    const getCsvReportButtonSubmitText =
      [CSV_REPORT_MODE_KEYS.schedulingReport].includes(this.csvReportCurrentMode) && this.scheduledReportId
        ? csvReportButtonSubmitTextSecond
        : csvReportButtonSubmitText
    const showModalMessage = [ELOPAGE_PROFILE_CABINETS.seller, ELOPAGE_PROFILE_CABINETS.team_member].includes(
      ELOPAGE_PROFILE_CABINETS[getCookies('cabinet_profile_type')]
    )

    return (
      <>
        {this.csvComponent(taxCsvLabel)}
        <EloModal
          cancel={false}
          size={SIZES.large}
          title={
            <div className='fields-container--bottom-align'>
              {newCsvLayout ? getCsvReportTitle : I18n.t('react.common.download_csv')}
              {newCsvLayout && prevStep && <i className='fas fa-chevron-left elo-modal__back-btn' onClick={prevStep} />}
            </div>
          }
          className={eloModalClasses}
          isOpen={this.openCsvDownloadModal}
          toggle={this.toggleCsvDownloadModal}
        >
          <div className='csv-download-modal'>
            {showModalMessage && (
              <div className='elo-modal__message-text elo-modal__message-text--centered'>
                {newCsvLayout ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: csvReportSubTitle,
                    }}
                  />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('react.shared.csv_modal_body', { logsLink: logsCsvLink }),
                    }}
                  />
                )}
              </div>
            )}
            {newCsvLayout ? (
              <>
                {[CSV_REPORT_MODE_KEYS.downloadReport, CSV_REPORT_MODE_KEYS.scheduledReports].includes(
                  this.csvReportCurrentMode
                ) && (
                  <SectionTabs
                    locationHash={locationHash}
                    tabs={TAB_TITLES}
                    activeTab={this.activeTab}
                    setActiveTab={this.toggleActiveTab}
                    sectionTabsClasses='elo-section-tabs elo-section-tabs--bottom-10'
                  />
                )}
                {[CSV_REPORT_MODE_KEYS.schedulingReport].includes(this.csvReportCurrentMode) && (
                  <div className='fields-container fields-container--evenly fields-container--without-margins fields-container--bottom-30 fields-container--centered'>
                    <SelectField
                      name='csv-scheduling-frequency'
                      onChange={this.setSelectedFrequency}
                      options={FREQUENCY_OPTIONS}
                      label={I18n.t('react.shared.csv_modal.frequency')}
                      value={this.selectedFrequency}
                      tooltipId='csv_scheduling_frequency'
                    />
                    <SelectField
                      key={this.selectedFrequencyDay}
                      name='buyer-campaign-id'
                      onChange={this.setSelectedFrequencyDay}
                      label={I18n.t('react.shared.common.day')}
                      value={this.selectedFrequencyDay}
                      options={dayOptions}
                      tooltipId='csv_scheduling_day'
                    />
                  </div>
                )}
                {[CSV_REPORT_MODE_KEYS.downloadReport, CSV_REPORT_MODE_KEYS.createReport].includes(
                  this.csvReportCurrentMode
                ) && (
                  <>
                    <div className='fields-container fields-container--with-right-margins fields-container--without-margins fields-container--bottom-20'>
                      <div className='field fields-container--49-percentage'>
                        <TextField
                          label={I18n.t('react.shared.csv_modal.optional_name')}
                          placeholder={I18n.t('react.shared.csv_modal.new_report')}
                          value={customName}
                          onChange={(value) => this.updateFormData({ customName: value })}
                          tooltipId='csv_name'
                        />
                      </div>
                      <div className='field fields-container--30-percentage'>
                        <SelectField
                          name='csv-saved-columns'
                          onChange={this.setSelectedSavedColumn}
                          options={this.savedColumnsList}
                          label={I18n.t('react.shared.csv_modal.saved_column')}
                          value={selectedSavedColumn}
                          tooltipId='csv_saved_columns'
                          placeholder={I18n.t('react.shared.csv_modal.select_filter')}
                        />
                      </div>
                      <div className='field'>
                        <SelectField
                          name='csv-decimal-separator'
                          key={this.separatorType}
                          onChange={this.setSeparatorType}
                          label={I18n.t('react.shared.csv_modal.decimal_separator')}
                          value={this.separatorType}
                          options={SEPARATOR_OPTIONS}
                          tooltipId='csv_decimal_separator'
                        />
                      </div>
                    </div>
                    <div className='fields-container__horisontal-separator fields-container__horisontal-separator--bottom-20' />
                    <CheckboxList
                      list={checkedOptions}
                      handleOnChange={this.setCheckedOptions}
                      downloadDisabled={
                        profile.profileType === ACTIVE_PROFILES.seller
                          ? storePagination.total > MAX_CABINET_CSV_COUNT
                          : storePagination.total > MAX_CSV_COUNT
                      }
                      checkboxIdKeys={['csvKey', 'sortingKey']}
                      newCsvLayout={newCsvLayout}
                    />
                    {withCustomFields && (
                      <div className='csv-download-modal__custom-fields'>
                        <CheckboxField
                          label={I18n.t('react.shared.csv_log.include_custom_fields')}
                          checked={includeCustomFields}
                          onChange={() => this.updateFormData({ includeCustomFields: !includeCustomFields })}
                          noMargin
                        />
                        <div className='csv-download-modal__vertical-separator'>|</div>
                        <CheckboxField
                          label={I18n.t('react.shared.csv_log.saved_selection')}
                          checked={saveColumns}
                          onChange={() => this.updateFormData({ saveColumns: !saveColumns })}
                          tooltipIdCheck='save_column_selection'
                        />
                      </div>
                    )}
                  </>
                )}
                {[CSV_REPORT_MODE_KEYS.scheduledReports].includes(this.csvReportCurrentMode) && (
                  <CsvScheduledReports
                    editItem={this.editScheduledReport}
                    setItemToDelete={this.setItemToDelete}
                    duplicateItem={this.duplicateScheduledReport}
                  />
                )}
              </>
            ) : (
              <>
                <div className='fields-container__horisontal-separator fields-container__horisontal-separator--full-margin' />
                <div className='fields-container fields-container--center-align fields-container--relative'>
                  <div className='fields-container fields-container--center-align'>
                    <RadioField
                      id={CSV_DOWNLOAD_TYPE.list}
                      value={csvType}
                      label={I18n.t('react.shared.csv_download_type.list')}
                      onChange={() => this.setCsvType(CSV_DOWNLOAD_TYPE.list)}
                    />
                    <div className='fields-container__vertical-separator'>|</div>
                    <RadioField
                      id={CSV_DOWNLOAD_TYPE.custom}
                      value={csvType}
                      label={I18n.t('react.shared.csv_download_type.custom')}
                      onChange={() => this.setCsvType(CSV_DOWNLOAD_TYPE.custom)}
                    />
                  </div>
                  {!isTypeList && (
                    <div className='fields-container--25-percentage fields-container--absolute-right'>
                      <SelectField
                        onChange={(value) => this.setSeparatorType(value)}
                        placeholder='Select separator'
                        value={options[0]}
                        options={options}
                      />
                    </div>
                  )}
                </div>
                <div className='fields-container__horisontal-separator fields-container__horisontal-separator--full-margin' />
                {isTypeList ? (
                  <CsvLogPreview
                    selectedCsvLog={this.selectedCsvLog}
                    updateSelectedCsvLog={this.updateSelectedCsvLog}
                  />
                ) : (
                  <div>
                    <CheckboxList
                      list={params || tableHeaders || []}
                      handleOnChange={this.setCheckedOptions}
                      downloadDisabled={
                        profile.profileType === ACTIVE_PROFILES.seller
                          ? storePagination.total > MAX_CABINET_CSV_COUNT
                          : storePagination.total > MAX_CSV_COUNT
                      }
                      checkboxIdKeys={['csvKey', 'sortingKey']}
                    />
                    {withCustomFields && (
                      <>
                        <div className='fields-container__horisontal-separator fields-container__horisontal-separator--with-top-margin' />
                        <CheckboxField
                          label={I18n.t('react.shared.csv_log.custom_field_label')}
                          checked={includeCustomFields}
                          onChange={() => this.updateFormData({ includeCustomFields: !includeCustomFields })}
                        />
                      </>
                    )}
                  </div>
                )}
              </>
            )}
            <div className={buttonsClasses}>
              <EloButton outlined onClick={this.toggleCsvDownloadModal}>
                {newCsvLayout ? I18n.t('react.shared.button.cancel') : I18n.t('react.shared.button.close')}
              </EloButton>
              {newCsvLayout ? (
                <EloButton onClick={buttonSubmit} id='buttonNewSchedule'>
                  {csvReportButtonSubmitIcon}
                  {getCsvReportButtonSubmitText}
                </EloButton>
              ) : (
                <EloButton
                  disabled={!processed && isTypeList}
                  onClick={() => (isTypeList ? this.downloadCsv() : this.createCsvLog())}
                >
                  {isTypeList ? I18n.t('react.common.download_csv') : I18n.t('react.common.generate_csv')}
                </EloButton>
              )}
            </div>
          </div>
        </EloModal>
        {openCsvProcessingModal && (
          <EloModal
            isOpen
            submit={false}
            toggle={() => this.toggleCsvModal('openCsvProcessingModal', !openCsvProcessingModal)}
            title={I18n.t('react.shared.csv_processing_modal.title')}
            cancelButtonChild={I18n.t('react.shared.button.close')}
            className='elo-modal--body-p-t-10'
          >
            <EloModal.Message centered>{I18n.t('react.shared.csv_processing_modal.message')}</EloModal.Message>
            <LoadingSpinner />
          </EloModal>
        )}
        {openCsvSuccessModal && (
          <EloModal
            isOpen
            submit={false}
            cancel={openCsvFinishModal}
            toggle={() => this.toggleCsvModal('openCsvSuccessModal', !openCsvSuccessModal)}
            title={I18n.t('react.shared.csv_success_modal.title')}
            customButtonProps={
              openCsvFinishModal
                ? false
                : {
                    onClick: this.downloadCsv,
                    children: (
                      <>
                        <i className='fas fa-download' />
                        {I18n.t('react.shared.button.download_csv')}
                      </>
                    ),
                  }
            }
            customButtonContainerClassName='elo-modal__bottom-buttons__center-block'
            iconProps={{ className: 'fas fa-check-circle' }}
            className='elo-modal--with-icons elo-modal--body-p-t-10'
          >
            <EloModal.Message centered>
              <span
                dangerouslySetInnerHTML={{
                  __html: I18n.t('react.shared.csv_success_modal.message', { logsLink: logsCsvLink }),
                }}
              />
            </EloModal.Message>
          </EloModal>
        )}
        <ConfirmationModal
          isOpen={!!this.itemToDelete}
          submit={this.deleteScheduledReport}
          toggle={() => this.setItemToDelete()}
          title={I18n.t('react.shared.csv_modal.remove_report_title')}
          message={I18n.t('react.shared.csv_modal.remove_report_description')}
          submitButtonChild={I18n.t('react.shared.button.delete')}
          size={SIZES.medium}
          customSubmitButtonProps={{
            pink: true,
          }}
        />
      </>
    )
  }
}

export default CsvDownloadModal
