import type { ApiClientV2Default } from 'utils/api-client/api-client.utils'

const BASE_URL = '/cabinet/plans/for_upgrade'

export interface UpgradePlan {
  id: number
  name: string
  description: string
  price: number
  recommended: boolean
  selected: boolean
  shortOption: string
  cover: string
  released: boolean
  best: boolean
}

export const createUpgradePlansApi = ({ GET }: ApiClientV2Default) => ({
  fetchList: () => GET<UpgradePlan[]>(BASE_URL),
})

export type UpgradePlansApi = ReturnType<typeof createUpgradePlansApi>
