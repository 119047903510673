import { override, toJS } from 'mobx'

import SharedStore from 'shared/stores/shared.store'
import * as api from '../api/salesTeamSettings.api'

export class SalesTeamSettingsStore extends SharedStore {
  storeName = 'SalesTeamSettingsStore'
  childApi = api // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  @override
  async fetchItem(id) {
    if (!id) {
      return
    }

    this.toggleLoading(true)
    const resp = await this.childApi.fetchItem(id, {
      expand: toJS(this.expands),
      counts: toJS(this.counts),
    })

    if (resp.success) {
      this.setItem(resp)
    } else {
      this.setItem({})
    }

    this.toggleLoading(false)
    return resp
  }
}

export default new SalesTeamSettingsStore()
